



























import ClearButton from '@/components/buttons/ClearButton.vue'
import { defineComponent } from '@vue/composition-api'
import { AdminsDTO, ClientSearchFiltersDTO, ClientStatus } from '@/services/client/types'
import { TranslateResult } from 'vue-i18n'
import ClientService from '@/services/client'

export default defineComponent({
  name: 'ClientSearchFilters',
  components: { ClearButton },
  props: { searchParams: { type: Object, default: Function } },
  model: { prop: 'searchParams' },
  data(): { admins: AdminsDTO[] } {
    return { admins: [] }
  },
  methods: {
    clear: function () {
      this.computedSearchParams.admin = undefined
      this.computedSearchParams.statuses = undefined
    },
    async fetchAdmins() {
      const { data } = await ClientService.admins()
      this.admins = data.data.items
    },
  },
  mounted() {
    this.fetchAdmins()
  },
  computed: {
    computedSearchParams: {
      get(): ClientSearchFiltersDTO {
        return this.searchParams
      },
      set(value: ClientSearchFiltersDTO): void {
        this.$emit('input', value)
      },
    },
    clientStatuses(): Array<{ label: TranslateResult; value: string }> {
      return [
        { label: this.$tc('active'), value: ClientStatus.ACTIVE },
        { label: this.$tc('pending'), value: ClientStatus.PENDING },
        { label: this.$tc('inactive'), value: ClientStatus.INACTIVE },
      ]
    },
  },
})
